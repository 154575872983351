import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#FAFAFA',
      neutralLight2: '#ECECEB',
      neutralLight1: '#B1B1B1',
      neutralDark5: '#232B22',
      neutralDark4: '#6E6E6E',
      neutralDark3: '#363636',
      neutralDark2: '#505051',
      neutralDark1: '#000000',
      primaryDark: '#505050',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'itc-avant-garde-gothic-pro', serif",
    paragraph: "'canada-type-gibson', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.primaryDark};
  font-family: ${theme.fontFamily.heading};
  font-weight: 300;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark3};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.3125rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 4.5rem;
      line-height: 5.125rem;

      @media (max-width: 1199px) {
        font-size: 3rem;
        line-height: 3.375rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 4.5rem;

      @media (max-width: 1199px) {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 2.5rem;
      line-height: 3.25rem;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    `,
    normal: css`
      color: ${theme.colors.variants.neutralDark3};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1.5rem;
      line-height: 2.125rem;
      letter-spacing: 0.055em;
      font-weight: 200;

      @media (max-width: 1199px) {
        font-size: 1.125rem;
        line-height: 1.625rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 1.125rem;
      line-height: 1.75rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.75rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 0.875rem;
        line-height: 1.1875rem;
      }
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralDark3};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.040em;
    font-weight: 200;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({ children }: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          children
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
